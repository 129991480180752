import { useEffect, useState } from "react";
import classNames from "classnames";
import { CheckIcon, StumiliDetailsLogoIcon } from "../assets/icons";

const ConectProcess = () => {
  const [isComplete, setIsComplete] = useState(false);

  const handleClick = () => {
    if (isComplete) return;
    setIsComplete(true);
  };

  /* useEffect(() => {
    const intervalId = setTimeout(() => {
      setIsComplete(true);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []); */

  return <div className="flex-auto overflow-auto flex flex-col relative">
    <img
      src="/png/processing-bg.png"
      alt="Stimuli input processing modal"
      className="w-full h-full absolute inset-0 opacity-25 z-10 rounded-t-xl"
    />

    <div className="absolute w-full h-3/4 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-10" />
    <div className="absolute w-full h-2/3 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-20" />
    <div className="absolute w-full h-3/5 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-25" />
    <div className="absolute w-full h-2/4 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-40" />
    <div className="absolute w-full h-2/5 bottom-0 z-20 bg-gradient-to-t from-white to-white opacity-50" />

    <div className="mt-[100px] relative w-full h-40 flex z-20 justify-center items-start">
      <div className="w-28 h-28 p-2 mt-5 relative rounded-full bg-white">
        <div className={classNames("absolute inset-1 border-4 border-[#FEEAFD] border-t-4 border-t-[#7A3DAA] border-b-4 border-b-[#7A3DAA] border-l-4 border-l-[#7A3DAA] rounded-full animate-spin", { "border-r-4 animate-none border-r-[#7A3DAA]": isComplete })} />
        <div className="relative w-full h-full p-1 rounded-full bg-white">
          <div className="w-full h-full">
            <div
              className=
              "w-full h-full flex justify-center items-center rounded-full bg-[#FFEAFE]"
            >
              {!isComplete ? <StumiliDetailsLogoIcon width={40} height={40} /> : <CheckIcon width={32} height={22} />}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="px-4 pt-1 pb-4 flex flex-col justify-center items-center relative z-20">
      <p className="mb-1 text-2xl font-semibold text-[#060606]">
        {!isComplete ? "Hold on tight! Generating insights.." : "Your Report is Ready! 🎉"}
      </p>

      {!isComplete && <p className="mb-6 text-sm text-center text-[#536787]">Might take a few minutes to craft personalized insights just for you</p>}

      {isComplete && <p className="max-w-[429px] w-full mb-6 text-sm text-center text-[#536787]">Your personalised insights have been crafted and are ready for you to download.</p>}

      <button onClick={handleClick} className="max-w-[308px] w-full p-3 flex justify-center items-center gap-x-2 rounded bg-[#7A3DAA] text-white">
        {
          !isComplete ?
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M11.7645 0L12.7813 2.74789L15.5292 3.7647L12.7813 4.78152L11.7645 7.52941L10.7477 4.78152L7.9998 3.7647L10.7477 2.74789L11.7645 0ZM6.11874 3.76471L7.64395 7.88655L11.7658 9.41176L7.64395 10.937L6.11874 15.0588L4.59352 10.937L0.47168 9.41176L4.59352 7.88655L6.11874 3.76471ZM12.8726 13.0115L12.2371 11.2941L11.6016 13.0115L9.88418 13.6471L11.6016 14.2826L12.2371 16L12.8726 14.2826L14.5901 13.6471L12.8726 13.0115Z" fill="white" />
            </svg> : <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.5 10V14.6667C16.5 15.0203 16.3595 15.3594 16.1095 15.6095C15.8594 15.8595 15.5203 16 15.1667 16H1.83333C1.47971 16 1.14057 15.8595 0.890524 15.6095C0.640476 15.3594 0.5 15.0203 0.5 14.6667V10C0.5 9.82319 0.570238 9.65362 0.695262 9.52859C0.820287 9.40357 0.989856 9.33333 1.16667 9.33333C1.34348 9.33333 1.51305 9.40357 1.63807 9.52859C1.7631 9.65362 1.83333 9.82319 1.83333 10V14.6667H15.1667V10C15.1667 9.82319 15.2369 9.65362 15.3619 9.52859C15.487 9.40357 15.6565 9.33333 15.8333 9.33333C16.0101 9.33333 16.1797 9.40357 16.3047 9.52859C16.4298 9.65362 16.5 9.82319 16.5 10ZM8.02833 10.4717C8.09025 10.5337 8.16377 10.5828 8.24471 10.6164C8.32564 10.6499 8.41239 10.6672 8.5 10.6672C8.58761 10.6672 8.67436 10.6499 8.75529 10.6164C8.83623 10.5828 8.90975 10.5337 8.97167 10.4717L12.305 7.13833C12.3669 7.07639 12.4161 7.00286 12.4496 6.92193C12.4831 6.841 12.5004 6.75426 12.5004 6.66667C12.5004 6.57907 12.4831 6.49233 12.4496 6.4114C12.4161 6.33047 12.3669 6.25694 12.305 6.195C12.2431 6.13306 12.1695 6.08393 12.0886 6.0504C12.0077 6.01688 11.9209 5.99963 11.8333 5.99963C11.7457 5.99963 11.659 6.01688 11.5781 6.0504C11.4971 6.08393 11.4236 6.13306 11.3617 6.195L9.16667 8.39083V0.666667C9.16667 0.489856 9.09643 0.320287 8.97141 0.195262C8.84638 0.070238 8.67681 0 8.5 0C8.32319 0 8.15362 0.070238 8.0286 0.195262C7.90357 0.320287 7.83333 0.489856 7.83333 0.666667V8.39083L5.63833 6.195C5.51324 6.06991 5.34358 5.99963 5.16667 5.99963C4.98976 5.99963 4.82009 6.06991 4.695 6.195C4.56991 6.32009 4.49963 6.48976 4.49963 6.66667C4.49963 6.84358 4.56991 7.01324 4.695 7.13833L8.02833 10.4717Z" fill="white" />
            </svg>
        }

        <span>{!isComplete ? "Processing..." : "Download Report"}</span>
      </button>
    </div>
  </div>
};

export default ConectProcess;
